<template>
  <b-container class="report px-3">
    <div class="mt-2 mb-2">
      <div class="d-flex flex-row justify-content-between">
        <div class="text-left">
          <h4 class="mr-1 d-inline-block align-top">Tài khoản</h4>
          <b-badge pill variant="info">{{ users.length }}</b-badge>
        </div>
      </div>
    </div>
    <b-row class="px-6 mb-2">
      <b-col cols="6">
        <b-button
          class="w-100"
          size="sm"
          variant="secondary"
          :to="{ name: 'userEdit', params: { type: 'agent' } }"
        >
          <b-icon icon="person-plus-fill" aria-hidden="true"></b-icon>
          Thêm Đại lý
        </b-button>
      </b-col>
      <b-col cols="6">
        <b-button
          class="w-100"
          size="sm"
          variant="primary"
          :to="{ name: 'userEdit', params: { type: 'user' } }"
        >
          <b-icon icon="person-plus-fill" aria-hidden="true"></b-icon>
          Thêm Người dùng
        </b-button>
      </b-col>
    </b-row>
    <div class="mt-2 mb-2 d-flex flex-column">
      <b-tabs
        v-model="tabIndex"
        active-nav-item-class="font-weight-bold text-uppercase text-danger"
        active-tab-class="font-weight-bold text-success_"
        content-class="mt-3"
      >
        <b-tab title="Đại lý">
          <div class="d-none d-md-flex mb-3">
            <div class="search-box d-flex w-100 flex-row align-items-center">
              <b-icon icon="search"></b-icon>
              <b-form-input
                v-model="search"
                class="search-form"
                placeholder="Tìm kiếm tài khoản"
              ></b-form-input>
            </div>
          </div>
          <div
            class="search-box mb-3 flex-row align-items-center d-flex d-sm-none"
          >
            <b-icon icon="search"></b-icon>
            <b-form-input
              v-model="search"
              class="search-form"
              placeholder="Tìm kiếm tài khoản"
            ></b-form-input>
          </div>
          <user-card
            v-for="user in users"
            :key="user.id"
            :idx="user.id"
            :data="user"
            :render="userType == 'agent'"
            @reload-users="fetchChild"
          ></user-card>
        </b-tab>
        <b-tab title="Người dùng">
          <div class="d-none d-md-flex mb-3">
            <div class="search-box d-flex w-100 flex-row align-items-center">
              <b-icon icon="search"></b-icon>
              <b-form-input
                v-model="search"
                class="search-form"
                placeholder="Tìm kiếm tài khoản"
              ></b-form-input>
            </div>
          </div>
          <div
            class="search-box mb-3 flex-row align-items-center d-flex d-sm-none"
          >
            <b-icon icon="search"></b-icon>
            <b-form-input
              v-model="search"
              class="search-form"
              placeholder="Tìm kiếm tài khoản"
            ></b-form-input>
          </div>
          <user-card
            v-for="user in users"
            :key="user.id"
            :idx="user.id"
            :data="user"
            :render="userType == 'user'"
            @reload-users="fetchChild"
          ></user-card>
        </b-tab>
      </b-tabs>
    </div>
  </b-container>
</template>

<script>
import UserCard from "@/components/UserCard.vue";

export default {
  data() {
    return {
      userType: "all", // 'all', 'agent', 'user'
      search: "",
      users: [],
      originUser: [],

      tabIndex: 1,

      deleteLayout: false,
      deleteId: null
    };
  },
  components: {
    UserCard
  },
  watch: {
    userType: function(newType) {
      this.filterAndSearch(newType, this.search);
    },
    search: function(newSearch) {
      this.filterAndSearch(this.userType, newSearch);
    },
    tabIndex: function(newIdx) {
      this.changeUserType(newIdx == 0 ? "agent" : "user");
    }
  },
  methods: {
    changeUserType(type) {
      this.userType = type;
    },
    async fetchChild() {
      this.search = "";

      let res = await this.$http.get("user/childs");
      if (res.data.code == 0) {
        this.originUser = [...res.data.data];
        this.filterAndSearch(this.userType, "");
      }
    },
    filterAndSearch(type, search) {
      if (type == "all") {
        if (search == "") {
          this.users = [...this.originUser];
          return;
        }

        this.users =
          search == ""
            ? [...this.originUser]
            : this.originUser.filter(function(e) {
                return e.name.includes(search);
              });
      } else {
        if (search == "") {
          this.users = this.originUser.filter(function(e) {
            return e.role == type;
          });
          return;
        }

        this.users = this.originUser.filter(function(e) {
          return e.role == type && e.name.includes(search);
        });
      }
    }
  },

  created() {
    this.userType = "user";
    this.fetchChild();
  }
};
</script>

<style>
.search-box {
  border: 1px solid #eee;
  margin: 0;
  padding: 0 15px;
}
.search-form {
  border: none;
}
.d-sm-none.search-box {
  border-left: none;
  border-right: none;
}
</style>
