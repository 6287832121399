<template>
  <b-card
    v-if="render"
    no-body
    :class="'mb-1 p-2 ' + (data.status == -1 ? 'lock' : '')"
  >
    <router-link
      class="row text-left"
      tag="div"
      :to="{ name: 'userEdit', params: { uid: data.id } }"
    >
      <b-col class="col-8">
        <span class="font-weight-bolder">{{ data.name }} </span>
        <span v-if="data.note">({{ data.note }})</span>
      </b-col>
      <b-col class="col-4 text-right">
        <span :id="'price_' + idx" class="text-has-info">{{
          priceToText
        }}</span>
        <b-tooltip :target="'price_' + idx" triggers="hover"
          >Giá 1 tháng</b-tooltip
        >
      </b-col>
    </router-link>
    <router-link
      class="row text-left"
      tag="div"
      :to="{ name: 'userEdit', params: { uid: data.id } }"
    >
      <b-col>
        <div v-if="data.role == 'agent'">
          <span class="text-has-info" :id="'pay_' + idx">{{ data.pay }}</span> |
          <span class="text-has-info" :id="'trial_' + idx">{{
            data.trial
          }}</span>
          <b-tooltip :target="'pay_' + idx" triggers="hover"
            >Tổng số tài khoản đã trả phí</b-tooltip
          >
          <b-tooltip :target="'trial_' + idx" triggers="hover"
            >Tổng số tài khoản dùng thử</b-tooltip
          >
        </div>
        <div v-else>
          <small class="font-italic text-muted">Ngày tạo: </small
          ><small class="font-italic text-muted">{{ createdDate }}</small>
        </div>
      </b-col>
      <b-col>
        <div v-if="data.role == 'user'">
          <small class="font-italic text-muted">Ngày hết hạn: </small>
          <small class="font-italic text-muted">{{ expiredDate }}</small>
        </div>
      </b-col>
    </router-link>
    <router-link
      :class="'row text-left' + (data.role == 'user' ? 'p-2_' : '')"
      tag="div"
      :to="{ name: 'userEdit', params: { uid: data.id } }"
    >
      <b-col v-if="data.role == 'agent'">
        <small class="font-italic text-muted">Ngày tạo: </small>
        <small class="font-italic text-muted">{{ createdDate }}</small>
      </b-col>
    </router-link>
    <b-row class="text-left">
      <router-link
        class="col-7"
        tag="div"
        :to="{ name: 'userEdit', params: { uid: data.id } }"
      >
        <small class="font-italic text-muted">Đăng nhập: </small>
        <small class="font-italic text-muted">{{ lastLoginDate }}</small>
      </router-link>
      <b-col class="text-right">
        <b-button-group size="sm">
          <b-button
            variant="primary"
            :to="{ name: 'userEdit', params: { uid: data.id } }"
          >
            <b-icon icon="gear"></b-icon>
          </b-button>
          <b-button variant="warning" @click="lockUser(data)">{{
            data.status == -1 ? "Mở khóa" : "Khóa"
          }}</b-button>
          <b-button variant="danger" @click="lockUserMoney(data)">{{
            data.status_money == 1 ? "Mở khóa thiếu tiền" : "Khóa thiếu tiền"
          }}</b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import moment from "moment";
import { Utilities } from "@/utilities";

export default {
  name: "user-card",
  props: ["data", "idx", "render"],
  computed: {
    createdDate: function() {
      return moment(this.data.created_at).format("DD/MM/YYYY");
    },
    expiredDate: function() {
      return moment(this.data.expired_time).format("DD/MM/YYYY");
    },
    lastLoginDate: function() {
      return this.data.last_login_at
        ? moment(this.data.last_login_at).format("DD/MM/YY hh:mm:ss")
        : "";
    },
    priceToText: function() {
      return Utilities.thousandFormat(this.data.price);
    }
  },
  methods: {
    async lockUserMoney(u) {
      let type = u.status_money == 1 ? "unlock" : "lock";
      let url = `user/status-money/${type}/${u.id}`;
      let res = await this.$http.put(url);

      if (res.data.code == 0) {
        this.makeToast(
          "Thành công",
          `${u.status_money == 1 ? "Mở khóa" : "Khóa"} thành công cho tài khoản ${
            u.name
          }`,
          "success",
          true
        );
        this.$emit("reload-users");
      }
    },
    async lockUser(u) {
      let type = u.status == -1 ? "unlock" : "lock";
      let url = `user/status/${type}/${u.id}`;
      let res = await this.$http.put(url);

      if (res.data.code == 0) {
        this.makeToast(
          "Thành công",
          `${u.status == -1 ? "Mở khóa" : "Khóa"} thành công cho tài khoản ${
            u.name
          }`,
          "success",
          true
        );
        this.$emit("reload-users");
      }
    },
    makeToast(title, content, variant, append = false) {
      this.$bvToast.toast(content, {
        title: title,
        autoHideDelay: 5000,
        appendToast: append,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-right  mb-5"
      });
    }
  }
};
</script>
<style>
.card .action-dr .btn {
  background-color: transparent !important;
  border: none;
  color: #6c757d;
}
.card .action-dr .btn.dropdown-toggle {
  color: #6c757d;
}
.text-has-info {
  border-bottom: 2px dotted;
}
.card.lock {
  background-color: #e8e8e8;
}
</style>
